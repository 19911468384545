import React from 'react';
import { gql } from '@apollo/client';
import {
  Datagrid,
  DateField,
  List,
  TextField,
  TopToolbar,
  CreateButton,
  ReferenceInput,
  AutocompleteInput,
  FunctionField,
} from 'react-admin';
import { DisplayHelpers } from 'utils/helpers';
import IdField from 'components/IdField';

const WageLogsListFilters = [
  <ReferenceInput label="Market" source="market_id" reference="Market" alwaysOn>
    <AutocompleteInput optionText="display_name" />
  </ReferenceInput>,
  <ReferenceInput label="Position" source="position_id" reference="Position" alwaysOn>
    <AutocompleteInput optionText="display_name" />
  </ReferenceInput>,
];

const WageLogsListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const WageLogsList = () => (
  <List actions={<WageLogsListActions />} filters={WageLogsListFilters} exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <FunctionField
        label="Hourly Rate"
        sortBy="hourly_rate_cents"
        render={(record) => DisplayHelpers.money(record?.hourly_rate_cents || 0)}
      />
      <TextField label="Position" source="position.display_name" sortBy="position_id" />
      <TextField label="Market" source="market.display_name" sortBy="market_id" />
      <TextField label="Work History ID" source="work_history_id" />
      <TextField label="Zip Code" source="zip_code" />
      <TextField label="Professional ID" source="professional_id" />
      <DateField label="Created At" source="created_at" />
    </Datagrid>
  </List>
);

WageLogsList.query = gql`
  query GET_LIST($input: WageLogQueryInput) {
    _wageLogsMeta(input: $input) {
      count
    }
    wageLogs(input: $input) {
      id
      professional_id
      hourly_rate_cents
      position {
        id
        display_name
      }
      market {
        id
        display_name
      }
      work_history_id
      zip_code
      created_at
      updated_at
    }
  }
`;

export default WageLogsList;
