import React from 'react';
import { SimpleForm, BooleanInput, required } from 'react-admin';
import { CreateInDialogButton } from '@react-admin/ra-form-layout';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import VmsTypeInput from 'components/VmsTypeInput';
import StateInput from 'components/StateInput';
import LocationInput from 'components/LocationInput';
import CompanyInput from 'components/CompanyInput';
import ConditionInput from 'components/ConditionInput';
import { useFormContext } from 'react-hook-form';
import { Box, Divider, Typography } from '@medely/web-components';
import isNil from 'lodash/isNil';

export enum RequirementFormType {
  medely,
  state,
  location,
  company,
  vms,
}

type RequirementFormValues = Record<string, any>;

type RequirementFormProps = {
  type?: RequirementFormType;
  defaultValues?: RequirementFormValues;
  transform?: (payload: RequirementFormValues) => RequirementFormValues;
};

enum RequirementFilterFields {
  job_type = 'job_type',
  kind = 'kind',
  position_id = 'position_id',
}

const transformPayload = (payload) => {
  if (payload.position_id !== undefined && payload.specialty_id !== undefined) {
    delete payload.position_id;
  }
  return payload;
};

const RequirementFormContent = ({ children }) => {
  const { watch } = useFormContext();
  const values = watch();

  const selectedRequirementFormField = Object.values(RequirementFilterFields).find(
    (field) => values && !isNil(values[field]),
  );

  const isFieldDisabled = (fieldName) =>
    selectedRequirementFormField && fieldName !== selectedRequirementFormField;

  return (
    <>
      <EnumSelectInput label="Requirement Type" source="cohort_eligibility" fullWidth />
      <ConditionInput fullWidth />

      {children}

      <Divider />
      <Box
        width="100%"
        pt={2}
        mb={1}
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.grey[200]}`,
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
        })}
      >
        <Typography variant="body2">Select only one of the following:</Typography>

        <EnumSelectInput
          resettable
          source="job_type"
          fullWidth
          disabled={isFieldDisabled(RequirementFilterFields.job_type)}
        />
        <EnumSelectInput
          resettable
          source="kind"
          fullWidth
          disabled={isFieldDisabled(RequirementFilterFields.kind)}
        />
        <PositionInput
          fullWidth
          disabled={
            isFieldDisabled(RequirementFilterFields.position_id) || !isNil(values['specialty_id'])
          }
          resettable={isNil(values['specialty_id'])}
        />
        <Box ml={3}>
          <SpecialtyInput positionSource="position_id" optionText="label" fullWidth />
        </Box>
      </Box>
      <Divider />

      <BooleanInput source="add_to_booked_jobs" defaultValue={false} fullWidth />
    </>
  );
};

export const RequirementForm = ({ type, defaultValues, ...rest }: RequirementFormProps) => {
  const additionalFields = (() => {
    switch (type) {
      case RequirementFormType.medely:
        return <BooleanInput defaultValue={true} source="medely" />;
      case RequirementFormType.state:
        return <StateInput validate={[required()]} fullWidth />;
      case RequirementFormType.location:
        return <LocationInput validate={[required()]} fullWidth />;
      case RequirementFormType.company:
        return <CompanyInput validate={[required()]} fullWidth />;
      case RequirementFormType.vms:
        return <VmsTypeInput validate={[required()]} source="vms_type_id" fullWidth />;
      default:
        return null;
    }
  })();
  return (
    <CreateInDialogButton fullWidth transform={transformPayload} {...rest}>
      <SimpleForm defaultValues={defaultValues}>
        <RequirementFormContent>{additionalFields}</RequirementFormContent>
      </SimpleForm>
    </CreateInDialogButton>
  );
};
