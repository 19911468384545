import enums, {
  JobStatusEnum,
  ProfessionalStatusEnum,
  ProfessionalAppStatusEnum,
  CredentialStatus,
  ProfessionalQualificationStatus,
  LicenseFieldType,
  LicenseFieldKind,
  LicenseKindEnum,
  PermissionGroupAccountTypes,
  PermissionGroupRoles,
  ProfessionalAppVersionEnum,
} from 'typings/enums';

export enum Months {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12,
}

export enum ScheduleType {
  flexible = 'flexible',
  weekdays = 'weekdays',
  weekends = 'weekends',
}

export enum Days {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum AccountType {
  PROFESSIONAL = 'professional',
  ADMINISTRATOR = 'administrator',
  FACILITY = 'facility',
  AGENCY = 'agency',
}

enum AccountStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

enum AdministratorDepartment {
  'Engineering' = 'Engineering',
  'Sales' = 'Sales',
  'Recruiting' = 'Recruiting',
  'Customer support' = 'Customer support',
  'HR' = 'HR',
  'Finance' = 'Finance',
  'Product' = 'Product',
  'Dispute' = 'Dispute',
}

enum AgencyStatus {
  Approved = 'approved',
  Pending = 'pending',
  Suspended = 'suspended',
}

enum AgencyLocationStatus {
  Approved = 'approved',
  Pending = 'pending',
  Deactivated = 'deactivated',
}

export enum AssignmentStatus {
  'available' = 'available',
  'canceled' = 'canceled',
  'matched' = 'matched',
  'booked' = 'booked',
  'unfilled' = 'unfilled',
  'completed' = 'completed',
  'w2_onboarding' = 'w2_onboarding',
  'missing_required_credentials' = 'missing_required_credentials',
  'in_progress' = 'in_progress',
  'draft' = 'draft',
}

const AssignmentContractRange = {
  'Less than $10,000': [null, 1000000],
  '$10,000 - $15,000': [1000000, 1500000],
  '$15,000 - $25,000': [1500000, 2500000],
  '$25,000 - $35,000': [2500000, 3500000],
  '$35,000 - $45,000': [3500000, 4500000],
  '$45,000 - $55,000': [4500000, 5500000],
  '$55,000 - $65,000': [5500000, 6500000],
  '$65,000 - $75,000': [6500000, 7500000],
  '$75,000 - $85,000': [7500000, 8500000],
  '$85,000 - $95,000': [8500000, 9500000],
  'More than $95,000': [9500000, null],
};

enum AssignmentPatientPopulation {
  pediatrics_adults = 'pediatrics_adults',
  adults = 'adults',
  pediatrics = 'pediatrics',
}

enum PositionKind {
  allied = 'allied',
  imaging_services = 'imaging_services',
  lvn = 'lvn',
  physician = 'physician',
  registered_nurse = 'registered_nurse',
}

enum CancellationWindow {
  'grace' = 'grace',
  'early' = 'early',
  'mid' = 'mid',
  'late' = 'late',
}

enum AssignmentRequestStatus {
  applied = 'applied',
  accepted = 'accepted',
  rejected = 'rejected',
  unapplied = 'unapplied',
  offer_sent = 'offer_sent',
  offer_declined = 'offer_declined',
  assignment_canceled = 'assignment_canceled',
  pre_apply = 'pre_apply',
}

enum TimecardStatus {
  open = 'open',
  submitted = 'submitted',
  approved = 'approved',
}

enum VMSApplicationStatus {
  submitted = 'submitted',
  approved = 'approved',
  rejected = 'rejected',
  'offer retracted' = 'offer retracted',
}

enum VMSAssignmentStatus {
  Open = 'Open',
  Closed = 'Closed',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}

enum VMSProfileStatus {
  submitted = 'submitted',
  approved = 'approved',
  rejected = 'rejected',
  pending = 'pending',
}

enum AssignmentRequestBonusType {
  'Sign-on' = 'Sign-on',
  'Extension' = 'Extension',
  'Completion' = 'Completion',
}

enum AssignmentRequestContractType {
  'Travel' = 'Travel',
  'Local' = 'Local',
}

enum ProfessionalReferenceStatus {
  checking = 'checking',
  review = 'review',
  rejected = 'rejected',
  accepted = 'accepted',
  no_response = 'no_response',
}

enum AssignmentRequestVMSRejectStatus {
  'Cancelled/closed order with VMS' = 'Cancelled/closed order with VMS',
  'Candidate does not fit criteria' = 'Candidate does not fit criteria',
  'Ineligible: Current/previous employee of facility or health system' = 'Ineligible: current/previous employee of facility or health system',
  'Ineligible: Do Not Return/Do Not Send' = 'Ineligible: Do Not Return/Do Not Send',
  'Incomplete/Incorrect submission' = 'Incomplete/Incorrect submission',
  'Did not pass interview' = 'Did not pass interview',
  'Max number of submissions' = 'Max number of submissions',
  'Max number of Medely applicants' = 'Max number of Medely applicants',
  'Pro no longer interested/available' = 'Pro no longer interested/available',
  'Saved for another traveler' = 'Saved for another traveler',
  'Not enough experience' = 'Not enough experience',
  'Submitted by another agency' = 'Submitted by another agency',
  'No reason provided' = 'No reason provided',
  'Max offers out/Position on Hold' = 'Max offers out/Position on Hold',
  'Aged Submission-Redirect' = 'Aged Submission-Redirect',
  'Professional unresponsive' = 'Professional unresponsive',
  'Missing requirements, not included on VMS order' = 'Missing requirements, not included on VMS order',
  'Pro did not complete VA/interview' = 'Pro did not complete VA/interview',
  'Order on hold per AM' = 'Order on hold per AM',
}

enum AssignmentRequestVMSCAMRejectStatus {
  'Cancelled/closed order on VMS' = 'Cancelled/closed order on VMS',
  'Does not have state license' = 'Does not have state license',
  'Incomplete submission/Missing required information' = 'Incomplete submission/Missing required information',
  'Ineligible: Current/previous employee of facility or health system' = 'Ineligible: Current/previous employee of facility or health system',
  'Ineligible: Does not live xx miles away from facility' = 'Ineligible: Does not live xx miles away from facility',
  'Max number of Medely applicants' = 'Max number of Medely applicants',
  'Missing required credentials' = 'Missing required credentials',
  'Not enough experience' = 'Not enough experience',
  'Pro unapplied in admin' = 'Pro unapplied in admin',
  'References do not meet criteria' = 'References do not meet criteria',
  'Staff profile has too many complaints' = 'Staff profile has too many complaints',
  'Submitted by another agency' = 'Submitted by another agency',
  'Submitted to different order on same VMS' = 'Submitted to different order on same VMS',
  'Too much RTO' = 'Too much RTO',
  'Unable to submit-already submitted to same HDO or facility' = 'Unable to submit-already submitted to same HDO or facility',
  'Human error in posting-incorrect details' = 'Human error in posting-incorrect details',
  'Auto-publish: raw details only, pro does not meet requirements' = 'Auto-publish: raw details only, pro does not meet requirements',
  'Medely assignment missing details/requirements from VMS order' = 'Medely assignment missing details/requirements from VMS order',
  'Order not taking submissions/on hold (admin not updated)-no redirect option offered' = 'Order not taking submissions/on hold (admin not updated)-no redirect option offered',
  'Order not taking submissions/on hold (admin not updated)-redirect option offered' = 'Order not taking submissions/on hold (admin not updated)-redirect option offered',
  'Order on hold in admin' = 'Order on hold in admin',
}

enum AssignmentRequestRejectStatus {
  'Does not have state license' = 'Does not have state license',
  'Ineligible: Currently/Previously worked at this facility/health system' = 'Ineligible: Currently/Previously worked at this facility/health system',
  'Ineligible: Does not live xx miles away from facility' = 'Ineligible: Does not live xx miles away from facility',
  'Missing required credentials' = 'Missing required credentials',
  'Not enough experience' = 'Not enough experience',
  'Other' = 'Other',
  'Pro changed mind' = 'Pro changed mind',
  'Unable to commit to posted hours/schedule' = 'Unable to commit to posted hours/schedule',
  'Bill Rate Drop' = 'Bill Rate Drop',
  'Too much RTO' = 'Too much RTO',
  'Assignment details changed' = 'Assignment details changed',
  'References do not meet criteria' = 'References do not meet criteria',
}

enum AssignmentBookedSource {
  'Career Builder' = 'Career Builder',
  'Craigslist' = 'Craigslist',
  'Facebook' = 'Facebook',
  'Glassdoor' = 'Glassdoor',
  'Google Jobs' = 'Google Jobs',
  'Indeed' = 'Indeed',
  'Instagram' = 'Instagram',
  'LinkedIn' = 'LinkedIn',
  'Medely ATS' = 'Medely ATS',
  'Professional Associations' = 'Professional Associations',
  'Referral Network' = 'Referral Network',
  'SimplyHired' = 'SimplyHired',
  'Twitter' = 'Twitter',
  'Zip Recruiter' = 'Zip Recruiter',
}

enum AssignmentBookedMethod {
  'Self Booked' = 'Self Booked',
  'Recruiter Booked' = 'Recruiter Booked',
  'Self Extended' = 'Self Extended',
  'Renegotiated' = 'Renegotiated',
}

enum LocationProfessional {
  location,
  professional,
}
enum RefundReason {
  requested_by_customer,
  duplicate,
  fraudulent,
}
enum RefundKind {
  full,
  partial,
}

enum RelationshipKind {
  favorite = 'favorite',
  block = 'block',
}

enum BlockReasonType {
  LocationBlockReason = 'LocationBlockReason',
  ProfessionalBlockReason = 'ProfessionalBlockReason',
}

enum LocationBlockReasonKind {
  clinical,
  non_clinical,
  reliability,
}

enum LocalRateRuleAppliesTo {
  'registered_nurse' = 'registered_nurse',
  'allied' = 'allied',
  'all' = 'all',
  'lvn' = 'lvn',
}

enum LocalRateRuleName {
  'travel' = 'Same Rate',
  'travel_offset_amount' = 'Fixed offset amount',
  'travel_offset_percentage' = 'Fixed offset percentage',
  'vms_order' = 'Listed in VMS order',
  'vms_order_or_travel' = 'Listed in VMS order if different than travel',
}

enum LocalRateRuleRateOrigin {
  'Same Rate' = 'travel',
  'Fixed offset amount' = 'travel_offset_amount',
  'Fixed offset percentage' = 'travel_offset_percentage',
  'Listed in VMS order' = 'vms_order',
  'Listed in VMS order if different than travel' = 'vms_order_or_travel',
}

enum ProfessionalBlockReasonKind {
  default,
}

enum TransferReversalKind {
  full,
  partial,
}

enum AdministratorStatus {
  active = 'active',
  disabled = 'disabled',
}

enum StatementStatus {
  open = 'open',
  due = 'due',
  partially_paid = 'partially_paid',
  paid = 'paid',
  past_due = 'past_due',
}

enum EducationDegreeType {
  Technical = 'Technical',
  Associates = 'Associates',
  Bachelors = 'Bachelors',
  Graduate = 'Graduate',
  Doctoral = 'Doctoral',
  Other = 'Other',
}

enum QualificationStatus {
  active,
  inactive,
}

enum QualificationType {
  license = 'license',
  certification = 'certification',
  document = 'document',
  question = 'q_and_a',
  signed_document = 'signed_document',
  background_check = 'background_check',
  'application Q&A' = 'application_q_and_a',
  sanctions_check = 'sanctions_check',
  work_history = 'work_history',
  typeform = 'typeform',
}

enum EmploymentType {
  per_diem,
  full_time,
  part_time,
  travel_contract,
  internship,
}

enum JobType {
  per_diem = 'per_diem',
  full_time = 'full_time',
  part_time = 'part_time',
  travel_contract = 'travel_contract',
  internship = 'internship',
}

enum UserVmsType {
  'Healthtrust' = 'Healthtrust',
  'Shiftwise' = 'Shiftwise',
  'Focus One' = 'Focus One',
  'Aya' = 'Aya',
  'Medical Solutions Plus' = 'Medical Solutions Plus',
  'MSA' = 'MSA',
  'HWL' = 'HWL',
  'Medefis' = 'Medefis',
  'Blackwell' = 'Blackwell',
  'GrapeTree Medical Staffing' = 'GrapeTree Medical Staffing',
  'Hospital Services Corporation' = 'Hospital Services Corporation',
  'Staffency' = 'Staffency',
  'Cross Country/B4 Staffing' = 'Cross Country/B4 Staffing',
  'B4Health' = 'B4Health',
  'Stafferlink' = 'Stafferlink',
  'Stat Staff' = 'Stat Staff',
  'Rightsourcing' = 'Rightsourcing',
  'Simplifi' = 'Simplifi',
  'Medely' = 'Medely',
  'Trio/AHSA' = 'Trio/AHSA',
  'Fieldglass' = 'Fieldglass',
  'Vector' = 'Vector',
  'Vizient' = 'Vizient',
}

enum UserOvertimeHoursFrequency {
  'weekly',
  'daily',
}

enum BillingGroupBilling {
  standard_billing = 'standard_billing',
  monthly_billing = 'monthly_billing',
  bi_monthly_billing = 'bi_monthly_billing',
  weekly_billing = 'weekly_billing',
  vms_billing = 'vms_billing',
}

export enum BillingGroupBillingRestricted {
  standard_billing = 'standard_billing',
  weekly_billing = 'weekly_billing',
}

enum BillingGroupPlan {
  'standard' = 'standard',
  'enterprise_w2' = 'enterprise_w2',
}

enum BillingGroupTier {
  'Low risk' = 'Low risk',
  'Low risk (new)' = 'Low risk (new)',
  'Medium risk' = 'Medium risk',
  'High risk (internal)' = 'High risk (internal)',
  'High risk (agency)' = 'High risk (agency)',
  'High risk (bankruptcy)' = 'High risk (bankruptcy)',
  'High risk (legal)' = 'High risk (legal)',
}

enum UserSource {
  'Facility referral (user on our system)' = 'facility_referral',
  'Professional referral (pro on our system)' = 'professional_referral',
  'Word of mouth industry referral (not on platform)' = 'word_of_mouth',
  'Direct mailer' = 'direct_mailer',
  'Prospect email marketing campaign' = 'marketing_campaign',
  'Search engine (google, bing, other)' = 'search_engine',
  'Social media (linkedin, facebook, twitter, instagram)' = 'social_media',
  'Cold outbound - AE backfill' = 'cold_outbound',
  'Account Executive self prospected' = 'account_executive_prospected',
  'Healthcare conference/trade show' = 'conference_trade_show',
  'Medely produced webinar' = 'medely_webinar',
  'User Import' = 'user_import',
  'Other' = 'other',
}

enum NursysEnrollmentStatus {
  clear,
  encumbered,
}

enum PhoneScreeningRequestStatus {
  pending = 'pending',
  scheduled = 'scheduled',
  started = 'started',
  completed = 'completed',
  canceled = 'canceled',
  no_show = 'no_show',
  reschedule_requested = 'reschdule_requested',
}

enum JobPatientPopulation {
  pediatrics_adults = 'pediatrics_adults',
  adults = 'adults',
  pediatrics = 'pediatrics',
}

enum OvertimeFrequency {
  weekly = 'weekly',
  daily = 'daily',
}

enum FinancialTransactionKind {
  ach = 'ach',
  bank_debit = 'bank_debit',
  check = 'check',
  credit = 'credit',
  credit_adjustment = 'credit_adjustment',
  credit_transfer = 'credit_transfer',
  refund = 'refund',
  merchant_fees = 'merchant_fees',
  other = 'other',
  stop_payment = 'stop_payment',
  stripe_ach_credit_transfer = 'stripe_ach_credit_transfer',
  stripe_ach_in_app = 'stripe_ach_in_app',
  user_credit = 'user_credit',
  user_error = 'user_error',
  virtual_card = 'virtual_card',
  write_off = 'write_off',
}

enum FinancialTransactionDataSource {
  stripe = 'stripe',
  bank_of_america = 'bank_of_america',
  silicon_valley_bank = 'silicon_valley_bank',
  internal = 'internal',
  jp_morgan = 'jp_morgan',
}

enum TaxableType {
  WAGES = 'wages',
  BONUS = 'bonus',
  TRAVEL_STIPEND = 'travel_stipend',
  TRAVEL_REIMBURSEMENT = 'travel_reimbursement',
  MEAL_STIPEND = 'meal_stipend',
  REIMBURSEMENT = 'reimbursement',
  PARKING_REIMBURSEMENT = 'parking_reimbursement',
  ORIENTATION = 'orientation',
  MEAL_PENALTY_FEE = 'meal_penalty_fee',
}

enum AdjustmentType {
  BONUS = 'bonus',
  COLLECTIONS_LATE_FEE = 'collections_late_fee',
  CREDIT_ADJUSTMENT = 'credit_adjustment',
  CREDIT_TRANSFER = 'credit_transfer',
  HOURLY_FACILITY_INITIATED_ADJUSTMENT = 'hourly_facility_initiated_adjustment',
  HOURLY_PRO_INITIATED_ADJUSTMENT = 'hourly_pro_initiated_adjustment',
  HOURLY_OTHER = 'hourly',
  INVOICING_FEE = 'invoicing_fee',
  PROCESSING_FEE = 'processing_fee',
  PARKING_REIMBURSEMENT = 'parking_reimbursement',
  ORIENTATION = 'orientation',
  OPT_OUT = 'opt_out',
  VMS_FEE = 'vms_fee',
  REBATE_PROMO_CREDIT = 'rebate_promo_credit',
  REBATE_FOUR_HOUR_MINIMUM = 'rebate_4hour_minimum',
  REBATE_LATE_CANCELLATION = 'rebate_late_cancellation',
  REBATE_BETTER_PRO_EXPERIENCE = 'rebate_better_pro_experience',
  REBATE_OTHER = 'rebate',
  EDUCATION_STIPEND = 'education_stipend',
  SHORT_PAY = 'short_pay',
  CANCELLATION_FEE = 'cancellation_fee',
  EARLY_PAYMENT_DISCOUNT = 'early_payment_discount',
  OTHER_FEES = 'other_fees',
  WRITE_OFF_RECOVERY = 'wo_recovery',
  MEAL_PENALTY_FEE = 'meal_penalty_fee',
  TRUST_AND_SAFETY_FEE_CREDIT = 'trust_and_safety_fee_credit',
  STATE_AND_LOCAL_SALES_TAX = 'state_and_local_sales_tax',
}

enum PayoutStatus {
  open = 'open',
  pending = 'pending',
  approved = 'approved',
  in_transit = 'in_transit',
  canceled = 'canceled',
  submitted = 'submitted',
  failed = 'failed',
  paid = 'paid',
  hold = 'hold',
  disputed = 'disputed',
}

enum PayoutKind {
  ten99 = 'ten99',
  w2 = 'w2',
}

enum CohortEligibility {
  medely_internal = 'medely_internal',
  saas = 'saas',
  both = 'both',
}

enum InvoiceStatus {
  pending = 'pending',
  approved = 'approved',
  sent = 'sent',
  paid = 'paid',
  due = 'due',
  past_due = 'past_due',
  disputed = 'disputed',
}

enum InvoiceVoucherProcessingStatus {
  'pending' = 'pending',
  'denied' = 'denied',
  'disputed' = 'disputed',
  'not_worked' = 'not_worked',
  'completed' = 'completed',
  'please_review' = 'please_review',
  'missing_timesheet' = 'missing_timesheet',
  'biweekly' = 'biweekly',
  'null_invoices' = 'null_invoices',
  'collections' = 'collections',
  'not_available' = 'not_available',
}

enum CapsReportStatus {
  pending,
  submitted,
  completed,
  failed,
}

enum OfferSource {
  email = 'email',
  sms = 'sms',
  recommended_jobs = 'recommended_jobs',
  other = 'other',
}

enum CovidRequirement {
  vaccine_or_negative_test = 'vaccine_or_negative_test',
  vaccine_or_declination = 'vaccine_or_declination',
  vaccine_only = 'vaccine_only',
  vaccine_booster = 'vaccine_booster',
  vaccine_booster_or_declination = 'vaccine_booster_or_declination',
  cms_mandate = 'cms_mandate',
}

enum LineOfCreditCents {
  '$0' = 0,
  '$1,000' = 100000,
  '$1,500' = 150000,
  '$2,000' = 200000,
  '$2,500' = 250000,
  '$3,500' = 350000,
  '$4,000' = 400000,
  '$4,500' = 450000,
  '$5,000' = 500000,
  '$5,500' = 550000,
  '$6,000' = 600000,
  '$6,500' = 650000,
  '$7,000' = 700000,
  '$7,500' = 750000,
  '$10,000' = 1000000,
  '$15,000' = 1500000,
  '$20,000' = 2000000,
  '$25,000' = 2500000,
  '$30,000' = 3000000,
  '$40,000' = 4000000,
  '$50,000' = 5000000,
}

enum NetTerm {
  '+ 0 days' = 0,
  '+ 7 days' = 7,
  '+ 15 days' = 15,
  '+ 21 days' = 21,
  '+ 30 days' = 30,
  '+ 45 days' = 45,
  '+ 60 days' = 60,
}

enum TaxFormPreference {
  e_delivery = 'e_delivery',
  mail = 'mail',
}

enum DisputeReason {
  time_adjustment = 'time_adjustment',
  pro_did_not_work_this_job = 'pro_did_not_work_this_job',
  missing_required_upload = 'missing_required_upload',
  other = 'other',
}

enum DisputeCategory {
  no_show = 'no_show',
  inaccurate_hours_reported = 'inaccurate_hours_reported',
  late_cancellation_fee = 'late_cancellation_fee',
  miscellaneous_fee = 'miscellaneous_fee',
  missing_required_upload = 'missing_required_upload',
  staff_performance = 'staff_performance',
  weekly_minimum_hours = 'weekly_minimum_hours',
  other = 'other',
}

enum DisputeResolution {
  'approved' = 'approved',
  'rejected' = 'rejected',
  'approved (email_disabled)' = 'approved_email_disabled',
  'rejected (email_disabled)' = 'rejected_email_disabled',
}

enum DisputeStatus {
  'new' = 'new',
  'in_progress' = 'in_progress',
  'on_hold' = 'on_hold',
  'resolved' = 'resolved',
  'form_530_uploaded_and_needs_review' = '530_uploaded_and_needs_review',
  'needs_additional_review' = 'needs_additional_review',
  'require_facility_email' = 'require_facility_email',
  'waiting_for_pro_to_respond' = 'waiting_for_pro_to_respond',
}

enum BillingGroupStatus {
  active = 'active',
  in_review = 'in_review',
  inactive = 'inactive',
}

enum NoteRecipient {
  agency = 'Agency',
  billing_group = 'BillingGroup',
  financial_transaction = 'FinancialTransaction',
  invoice = 'Invoice',
  location = 'Location',
  professional = 'Professional',
}

enum PersonableType {
  professional = 'Professional',
  location = 'Location',
  billing_group = 'BillingGroup',
}

enum TraumaLevel {
  'N/A' = 'not_applicable',
  'Level I' = 'level_i',
  'Level II' = 'level_ii',
  'Level III' = 'level_iii',
  'Level IV' = 'level_iv',
  'Level V' = 'level_v',
}

enum ProfessionalType {
  marketplace = 'marketplace',
  irp = 'irp',
  agency = 'agency',
}

enum AgencyQualificationStatus {
  review = 'review',
  approved = 'approved',
  rejected = 'rejected',
  incomplete = 'incomplete',
  expired = 'expired',
  archived = 'archived',
  uploaded_in_error = 'uploaded_in_error',
  pending = 'pending',
}

enum ProfessionalWeeklyOvertimeSettings {
  allow_all = 'allow_all',
  allow_select = 'allow_select',
  block_all = 'block_all',
}

export const ENUMS = {
  Administrator: {
    status: AdministratorStatus,
    department: AdministratorDepartment,
  },
  Agency: {
    status: AgencyStatus,
  },
  AgencyQualification: {
    status: AgencyQualificationStatus,
  },
  AgencyLocation: {
    status: AgencyLocationStatus,
  },
  ApplicationRequirement: {
    kind: PositionKind,
  },
  Assignment: {
    status: AssignmentStatus,
    patient_population: AssignmentPatientPopulation,
    schedule_type: ScheduleType,
    vms_type: UserVmsType,
    'search.plan': BillingGroupPlan,
    'search.vms_type': UserVmsType,
    'search.assignment_request_status': AssignmentRequestStatus,
    'search.phone_screening_request_status': PhoneScreeningRequestStatus,
    booked_source: AssignmentBookedSource,
    booked_method: AssignmentBookedMethod,
  },
  Company: {
    'search.plan': BillingGroupPlan,
  },
  Job: {
    status: JobStatusEnum,
    patient_population: JobPatientPopulation,
    'search.billing_group_plan': BillingGroupPlan,
    offer_source: OfferSource,
  },
  JobBilling: {
    'search.job_status': JobStatusEnum,
  },
  ProfessionalReference: {
    status: ProfessionalReferenceStatus,
  },
  Position: {
    kind: PositionKind,
  },
  Timecard: {
    status: TimecardStatus,
  },
  AssignmentRequest: {
    status: AssignmentRequestStatus,
    vms_reject_reason: AssignmentRequestVMSRejectStatus,
    vms_application_status: VMSApplicationStatus,
    vms_profile_status: VMSProfileStatus,
    bonus_type: AssignmentRequestBonusType,
    contract_type: AssignmentRequestContractType,
    cam_reject_reason: AssignmentRequestVMSCAMRejectStatus,
    'search.assignment_status': AssignmentStatus,
    'search.professional_type': ProfessionalType,
    'assignment.status': AssignmentStatus,
    offer_source: OfferSource,
    rejection_reason: AssignmentRequestRejectStatus,
  },
  BackgroundCheck: {
    candidate_status: enums.background_check.candidate_status,
    report_status: enums.background_check.report_status,
  },
  BalanceSheet: {
    'search.month': Months,
  },
  BillingGroup: {
    vms_type: UserVmsType,
    'search.vms_type': UserVmsType,
    status: BillingGroupStatus,
    billing: BillingGroupBilling,
    plan: BillingGroupPlan,
    source: UserSource,
    overtime_hours_frequency: UserOvertimeHoursFrequency,
    line_of_credit_cents: LineOfCreditCents,
    net_term: NetTerm,
    tier: BillingGroupTier,
  },
  FundingSource: {
    personable_type: PersonableType,
  },
  CancellationReason: {
    role: LocationProfessional,
  },
  Refund: {
    reason: RefundReason,
    kind: RefundKind,
  },
  Relationship: {
    kind: RelationshipKind,
    professional_type: ProfessionalType,
  },
  PermissionGroup: {
    account_type: PermissionGroupAccountTypes,
    role: PermissionGroupRoles,
  },
  Account: {
    account_type: AccountType,
    status: AccountStatus,
  },
  TransferReversal: {
    kind: TransferReversalKind,
  },
  Professional: {
    'search.status': ProfessionalStatusEnum,
    status: ProfessionalStatusEnum,
    application_status: ProfessionalAppStatusEnum,
    application_version: ProfessionalAppVersionEnum,
    kind: PositionKind,
    'search.professional_type': ProfessionalType,
  },
  ProfessionalQualification: {
    status: ProfessionalQualificationStatus,
  },
  Statement: {
    status: StatementStatus,
  },
  Credential: {
    status: CredentialStatus,
    'search.kind': LicenseKindEnum,
    'license.kind': LicenseKindEnum,
  },
  License: {
    kind: LicenseKindEnum,
  },
  LicenseField: {
    kind: LicenseFieldKind,
    field_type: LicenseFieldType,
  },
  LocalRateRule: {
    applies_to: LocalRateRuleAppliesTo,
    rate_origin: LocalRateRuleRateOrigin,
    rule_name: LocalRateRuleName,
  },
  BlockReason: {
    type: BlockReasonType,
  },
  LocationBlockReason: {
    kind: LocationBlockReasonKind,
  },
  RateSetting: {
    charge_overtime_frequency: OvertimeFrequency,
    payout_overtime_frequency: OvertimeFrequency,
  },
  ProfessionalBlockReason: {
    kind: ProfessionalBlockReasonKind,
  },
  NursysEnrollment: {
    status: NursysEnrollmentStatus,
  },
  PhoneScreeningRequest: {
    status: PhoneScreeningRequestStatus,
  },
  FinancialTransaction: {
    kind: FinancialTransactionKind,
    data_source: FinancialTransactionDataSource,
  },
  ExpenseAdjustment: {
    taxable_type: TaxableType,
  },
  RevenueAdjustment: {
    adjustment_type: AdjustmentType,
  },
  Payout: {
    status: PayoutStatus,
    'search.status': PayoutStatus,
    kind: PayoutKind,
  },
  Invoice: {
    status: InvoiceStatus,
    'search.status': InvoiceStatus,
    voucher_processing_status: InvoiceVoucherProcessingStatus,
  },
  CapsReport: {
    status: CapsReportStatus,
  },
  Cancellation: {
    window: CancellationWindow,
  },
  Education: {
    degree_type: EducationDegreeType,
  },
  WorkHistory: {
    employment_type: EmploymentType,
    trauma_level: TraumaLevel,
  },
  WageLog: {
    job_type: JobType,
  },
  VmsAssignment: {
    'search.vms_status': VMSAssignmentStatus,
  },
  Requirement: {
    kind: PositionKind,
    job_type: PayoutKind,
    cohort_eligibility: CohortEligibility,
  },
  State: {
    covid_requirement: CovidRequirement,
  },
  JobTemplate: {
    patient_population: JobPatientPopulation,
    kind: PositionKind,
  },
  StripeAccount: {
    tax_form_preference: TaxFormPreference,
  },
  Dispute: {
    reason: DisputeReason,
    category: DisputeCategory,
    resolution: DisputeResolution,
    status: DisputeStatus,
    tax_type: PayoutKind,
  },
  Location: {
    covid_requirement: CovidRequirement,
    'search.plan': BillingGroupPlan,
    professional_weekly_overtime_settings: ProfessionalWeeklyOvertimeSettings,
  },
  Qualification: {
    qualification_type: QualificationType,
    status: QualificationStatus,
  },
  Note: {
    recipient: NoteRecipient,
  },
};
