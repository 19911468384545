import React from 'react';
import { gql } from '@apollo/client';
import { FunctionField, Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import LinkField from 'components/LinkField';
import { DisplayHelpers } from 'utils/helpers';

const WageLogsShow = () => (
  <Show actions={null}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <FunctionField
        label="Hourly Rate"
        sortBy="hourly_rate_cents"
        render={(record) =>
          record?.hourly_rate_cents ? DisplayHelpers.money(record?.hourly_rate_cents) : undefined
        }
      />
      <FunctionField
        label="Not Wanted Rate"
        sortBy="not_wanted_rate_cents"
        render={(record) =>
          record?.not_wanted_rate_cents
            ? DisplayHelpers.money(record?.not_wanted_rate_cents)
            : undefined
        }
      />
      <FunctionField
        label="Requested Rate"
        sortBy="requested_rate_cents"
        render={(record) =>
          record?.requested_rate_cents
            ? DisplayHelpers.money(record?.requested_rate_cents)
            : undefined
        }
      />
      <LinkField label="Position ID" source="position.id" />
      <LinkField label="Market ID" source="market.id" />
      <LinkField label="Professional ID" source="professional.id" />
      <LinkField label="Administrator ID" source="administrator_id" />
      <LinkField label="Work History ID" source="work_history.id" />
      <TextField label="Facility Type ID" source="facility_type_id" />
      <TextField label="Job Type" source="job_type" />
      <TextField label="Description" source="description" />
      <TextField label="Source" source="source" />
      <TextField label="Zip Code" source="zip_code" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

WageLogsShow.query = gql`
  query GET_ONE($id: Int!) {
    wageLog(id: $id) {
      id
      hourly_rate_cents
      work_history {
        id
      }
      zip_code
      position {
        id
      }
      market {
        id
      }
      professional {
        id
      }
      not_wanted_rate_cents
      requested_rate_cents
      administrator_id
      facility_type_id
      job_type
      description
      source
      created_at
      updated_at
    }
  }
`;

export default WageLogsShow;
