import React from 'react';
import {
  ReferenceManyField,
  Datagrid,
  DateField,
  TextField,
  useRecordContext,
  Pagination,
  FunctionField,
} from 'react-admin';
import QuickCreateButton from 'components/QuickCreateButton';
import { WageLogsFormFields } from 'resources/wage_logs/Create';
import { DisplayHelpers } from 'utils/helpers';

const WageLogsTab = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <div>
      <QuickCreateButton
        initialValues={{ professional_id: record.id }}
        resource="WageLog"
        title="Add Wage Log"
      >
        <WageLogsFormFields quickCreate />
      </QuickCreateButton>

      <ReferenceManyField
        reference="WageLog"
        target="professional_id"
        source="id"
        pagination={<Pagination />}
        data-testid="professional_show-wage_logs_table"
        sort={{ field: 'id', order: 'ASC' }}
      >
        <Datagrid bulkActionButtons={false} rowClick="show">
          <TextField source="id" label="ID" />
          <FunctionField
            label="Hourly Rate"
            sortBy="hourly_rate_cents"
            render={(record) => DisplayHelpers.money(record?.hourly_rate_cents || 0)}
          />
          <TextField label="Position" source="position.display_name" sortBy="position_id" />
          <TextField label="Market" source="market.display_name" sortBy="market_id" />
          <TextField label="Work History ID" source="work_history_id" />
          <TextField label="Zip Code" source="zip_code" />
          <TextField label="Professional ID" source="professional_id" />
          <DateField label="Created At" source="created_at" />
        </Datagrid>
      </ReferenceManyField>
    </div>
  );
};

export default WageLogsTab;
