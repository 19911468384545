import React, { PropsWithChildren } from 'react';
import {
  TextField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  SimpleShowLayout,
  DeleteWithConfirmButton,
  useRecordContext,
} from 'react-admin';
import IdField from 'components/IdField';
import { Box, Typography } from '@mui/material';
import { currentTimeZone } from 'utils/time';
import DateTimeField from 'components/DateTimeField';
import { RequirementForm, RequirementFormType } from 'resources/requirements/RequirementForm';

const RequirementsDataGrid = ({ children }: PropsWithChildren<{}>) => (
  <Datagrid bulkActionButtons={false}>
    <IdField label="ID" source="id" />
    <TextField label="Condition Name" source="condition.label" />
    <DateTimeField label="Created At" source="created_at" timeZone={currentTimeZone()} />
    <TextField source="job_type" />
    <TextField source="kind" />
    <TextField label="Position" source="position.display_name" />
    <TextField label="Specialty" source="specialty.label" />
    {children}
  </Datagrid>
);

const RequirementsTab = ({
  showLocationRequirements = true,
  showCompanyRequirements = true,
  showStateRequirements = true,
  locationRequirementsFilter = {},
  locationRequirementsLabel = 'Location Requirements',
}) => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  return (
    <SimpleShowLayout>
      {showLocationRequirements && (
        <ReferenceManyField
          pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
          label=""
          reference="Requirement"
          filter={{
            search: { location_requirements: true },
            ...locationRequirementsFilter,
          }}
          target="location_id"
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography gutterBottom variant="h6">
              {locationRequirementsLabel}
            </Typography>
            <RequirementForm
              type={RequirementFormType.location}
              defaultValues={{ location_id: record.id }}
            />
          </Box>
          <RequirementsDataGrid>
            <TextField label="Cohort" source="cohort_eligibility" />
            <DeleteWithConfirmButton />
          </RequirementsDataGrid>
        </ReferenceManyField>
      )}

      {showCompanyRequirements && (
        <>
          <Typography variant="h6" gutterBottom>
            Company Requirements
          </Typography>
          <ReferenceManyField
            pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
            label=""
            reference="Requirement"
            filter={{ search: { company_requirements: true } }}
            target="company_id"
            source="company_id"
          >
            <RequirementsDataGrid>
              <TextField label="Cohort" source="cohort_eligibility" />
            </RequirementsDataGrid>
          </ReferenceManyField>
        </>
      )}

      {showStateRequirements && (
        <>
          <Typography variant="h6" gutterBottom>
            State Requirements
          </Typography>
          <ReferenceManyField
            pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
            label=""
            reference="Requirement"
            filter={{ search: { state_requirements: true } }}
            target="state_id"
            source="state_id"
          >
            <RequirementsDataGrid />
          </ReferenceManyField>
        </>
      )}
    </SimpleShowLayout>
  );
};

export default RequirementsTab;
