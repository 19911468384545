import { Datagrid, TextField, DeleteWithConfirmButton, BooleanField } from 'react-admin';
import { useLocation } from 'react-router-dom';
import IdField from 'components/IdField';
import List from 'components/List';
import React from 'react';
import { gql } from '@apollo/client';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import VmsTypeInput from 'components/VmsTypeInput';
import StateInput from 'components/StateInput';
import LinkField from 'components/LinkField';
import LocationInput from 'components/LocationInput';
import CompanyInput from 'components/CompanyInput';
import ConditionInput from 'components/ConditionInput';
import { RequirementForm, RequirementFormType } from 'resources/requirements/RequirementForm';

const RequirementsList = () => {
  const location = useLocation();
  const pathname = location.pathname;
  let type: RequirementFormType | undefined;
  let sourceType = '';
  const RequirementFilters = [
    <EnumSelectInput alwaysOn resettable source="kind" />,
    <EnumSelectInput alwaysOn resettable source="job_type" />,
    <PositionInput alwaysOn label="Positions (Any)" source="search.position_id" />,
    <SpecialtyInput alwaysOn source="specialty_id" optionText="label" />,
  ];
  if (pathname.includes('Requirement/Medely')) {
    type = RequirementFormType.medely;
    sourceType += 'Medely ';
  } else if (pathname.includes('Requirement/Company')) {
    type = RequirementFormType.company;
    sourceType += 'Company ';
    RequirementFilters.unshift(<CompanyInput alwaysOn />);
  } else if (pathname.includes('Requirement/Location')) {
    type = RequirementFormType.location;
    sourceType += 'Location ';
    RequirementFilters.unshift(<LocationInput alwaysOn />);
  } else if (pathname.includes('Requirement/VMS')) {
    type = RequirementFormType.vms;
    sourceType += 'VMS ';
    RequirementFilters.unshift(<VmsTypeInput alwaysOn />);
  } else if (pathname.includes('Requirement/State')) {
    type = RequirementFormType.state;
    sourceType += 'State ';
    RequirementFilters.unshift(<StateInput alwaysOn />);
  } else {
    sourceType = 'All';
  }
  RequirementFilters.unshift(<ConditionInput alwaysOn resettable />);

  return (
    <List
      title={`${sourceType}Requirements`}
      exporter={false}
      filters={RequirementFilters}
      actions={<RequirementForm type={type} />}
    >
      <Datagrid bulkActionButtons={false}>
        <IdField />
        <TextField label="Condition Name" source="condition.label" />
        {type === RequirementFormType.state && <TextField source="state.abbreviation" />}
        {type === RequirementFormType.location && <LinkField source="location_id" />}
        {type === RequirementFormType.company && (
          <LinkField label="Company" source="company_id" resource="Company" />
        )}
        {type === RequirementFormType.vms && <TextField label="VMS Type" source="vms_type.name" />}
        {[RequirementFormType.company, RequirementFormType.location].includes(type) && (
          <TextField label="Cohort" source="cohort_eligibility" />
        )}
        <TextField source="job_type" sortable={false} />
        <TextField source="kind" />
        <TextField label="Position" source="position.display_name" />
        <TextField label="Specialty" source="specialty.label" />
        <BooleanField source="required" />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};

RequirementsList.query = gql`
  query GET_LIST($input: RequirementQueryInput) {
    requirements(input: $input) {
      id
      kind
      position {
        id
        display_name
      }
      specialty {
        id
        label
      }
      medely
      company_id
      vms_type_id
      vms_type {
        id
        name
      }
      state {
        id
        abbreviation
      }
      location_id
      condition {
        id
        label
      }
      required
      created_at
      job_type
      cohort_eligibility
    }
    _requirementsMeta(input: $input) {
      count
    }
  }
`;

export default RequirementsList;
