import React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  FunctionField,
  SimpleShowLayout,
} from 'react-admin';
import { format } from 'date-fns';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';
import Heading from 'components/Heading';
import { DisplayHelpers } from 'utils/helpers';
import { useRecordContext } from 'react-admin';
import _ from 'lodash';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const formatDate = (val: string | undefined) =>
  val ? format(new Date(val), 'MM/dd/yyyy HH:mm O') : '';

const ApplicationTab = () => (
  <SimpleShowLayout>
    <LinkField typename="Assignment" label="Sign Up Assignment ID" source="signup_assignment_id" />
    <Heading>Phone Screening</Heading>
    <FunctionField
      label="Phone Screen Date And Time"
      render={(val) => formatDate(val?.phone_screen_date_and_time)}
      data-testid="professional_show-phone_screen_date_and_time_field"
    />
    <FunctionField
      label="Screening Recruiter"
      render={(val) => val?.screening_recruiter?.account?.name}
      data-testid="professional_show-screening_recruiter_field"
    />
    <TextField
      source="calendly_invitee_uuid"
      data-testid="professional_show-calendly_invitee_uuid_field"
    />
    <TextField
      label="Phone Number"
      source="account.phone_number"
      data-testid="professional_show-phone_number_field"
    />
    <TextField source="quick_note" data-testid="professional_show-quick_note_field" />

    <Heading>Positions &amp; Experience</Heading>
    <FunctionField
      label="Professional Kind"
      source="kinds"
      render={DisplayHelpers.professional.kinds}
      data-testid="professional_show-kinds_field"
    />
    <ReferenceManyField
      perPage={100}
      label="Positions"
      reference="ProfessionalPosition"
      target="professional_id"
    >
      <Datagrid bulkActionButtons={false} data-testid="professional_show-positions_table">
        <IdField resource="ProfessionalPosition" />
        <TextField
          label="Name"
          source="position.display_name"
          data-testid="professional_show-positions_name_field"
        />
        <TextField
          source="years_of_experience"
          data-testid="professional_show-positions_years_of_experience_field"
        />
      </Datagrid>
    </ReferenceManyField>
    <ReferenceManyField
      perPage={100}
      label="Specialties"
      reference="ProfessionalSpecialty"
      target="professional_id"
    >
      <Datagrid bulkActionButtons={false} data-testid="professional_show-specialties_table">
        <IdField resource="ProfessionalSpecialty" />
        <TextField
          label="Name"
          source="specialty.name"
          data-testid="professional_show-specialties_name_field"
        />
        <TextField
          label="Label"
          source="specialty.label"
          data-testid="professional_show-specialties_label_field"
        />
      </Datagrid>
    </ReferenceManyField>

    <Heading>Recommended Positions</Heading>
    <RecommendedPositionsSection />

    <Heading>Credentials</Heading>
    <ReferenceManyField
      perPage={100}
      label=""
      reference="ProfessionalQualification"
      target="professional_id"
      filter={{ search: { qualification_types: ['license', 'certification', 'document'] } }}
    >
      <Datagrid bulkActionButtons={false} data-testid="professional_show-credentials_table">
        <IdField resource="ProfessionalQualification" />
        <TextField
          label="Credential"
          source="qualification.long_name"
          data-testid="professional_show-credentials_credential_field"
        />
        <FunctionField
          label="state"
          render={(record) =>
            record?.professional_qualification_attributes.find((attribute) => !!attribute.state)
              ?.state
          }
          data-testid="professional_show-credentials_state_field"
        />
        <FunctionField
          label="Original Issue Date"
          render={(record) =>
            record?.professional_qualification_attributes.find((attribute) =>
              attribute.qualification_attribute?.slug.includes('original_issue_date'),
            )?.value
          }
          data-testid="professional_show-credentials_original_issue_date_field"
        />
        <FunctionField
          label="Expires"
          render={(record) =>
            record?.professional_qualification_attributes.find(
              (attribute) => attribute?.type.split('::')[1] === 'ExpirationDate',
            )?.value
          }
          data-testid="professional_show-credentials_expiration_date_field"
        />
        <TextField source="status" data-testid="professional_show-credentials_status_field" />
      </Datagrid>
    </ReferenceManyField>

    <Heading>Question &amp; Answer Entries</Heading>
    <ReferenceManyField
      perPage={100}
      label=""
      reference="ProfessionalQualification"
      target="professional_id"
      filter={{ search: { qualification_types: ['application_q_and_a'] } }}
    >
      <Datagrid bulkActionButtons={false} data-testid="professional_show-qa_table">
        <IdField resource="ProfessionalQualification" />
        <FunctionField
          label="Question"
          render={(record) =>
            record.professional_qualification_attributes[0].qualification_attribute.name
          }
          data-testid="professional_show-qa_question_field"
        />
        <FunctionField
          label="Answer"
          render={(record) => record.professional_qualification_attributes[0].value}
          data-testid="professional_show-qa_answer_field"
        />
        <TextField source="status" data-testid="professional_show-qa_status_field" />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);
const RecommendedPositionsSection = () => {
  const record: any = useRecordContext();

  // Merge recommended positions from each position
  const positions = record.positions || [];
  const allRecommended = positions.reduce(
    (acc: any[], pos: any) => acc.concat(pos.recommended_positions || []),
    [],
  );
  const uniquePositions = _.uniqBy(allRecommended, 'id');

  // Get IDs of positions added by the professional
  const professionalPositionsIds = (record.professional_positions || []).map(
    (pp: any) => pp.position.id,
  );

  // Set 'added' status based on ID presence;
  const enrichedPositions = uniquePositions.map((pos: any) => ({
    ...pos,
    added: professionalPositionsIds.includes(pos.id),
  }));

  if (enrichedPositions.length === 0) {
    return <div data-testid="recommended-positions_empty">No Recommended Positions</div>;
  }

  return (
    <Table data-testid="recommended-positions_table">
      <TableHead>
        <TableRow>
          <TableCell>Offered</TableCell>
          <TableCell>Added</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {enrichedPositions.map((pos: any) => (
          <TableRow key={pos.id}>
            <TableCell>{pos.display_name}</TableCell>
            <TableCell>{pos.added ? '✓' : '✕'}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ApplicationTab;
