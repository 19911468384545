import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import AdditiveRequirementsTable from './AdditiveRequirementsTable';

const RequirementsTab = () => (
  <SimpleShowLayout>
    <AdditiveRequirementsTable />
  </SimpleShowLayout>
);

export default React.memo(RequirementsTab);
