import ProfessionalInput from 'components/ProfessionalInput';
import AdministratorInput from 'components/Filter/AdministratorInput';
import React from 'react';
import {
  AutocompleteInput,
  Create,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import ReferenceInput from 'components/ReferenceInput';
import PositionInput from 'components/PositionInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

type WageLogsFormFieldsProps = {
  quickCreate?: boolean;
};

const SOURCE_OPTIONS = [{ id: 'other', name: 'Other' }];

export const WageLogsFormFields = ({ quickCreate = false }: WageLogsFormFieldsProps) => (
  <>
    {!quickCreate && <ProfessionalInput />}
    <AdministratorInput
      label="Administrator"
      source="administrator_id"
      resettable
      sort={{ field: 'first_name', order: 'ASC' }}
    />
    <PositionInput fullWidth={quickCreate} />
    <ReferenceInput source="facility_type_id" reference="LocationType" fullWidth={quickCreate}>
      <AutocompleteInput optionText="label" />
    </ReferenceInput>
    <NumberInput label="Work History ID" source="work_history_id" fullWidth={quickCreate} />
    <NumberInput label="Hourly Rate (Cents)" source="hourly_rate_cents" fullWidth={quickCreate} />
    <NumberInput
      label="Not Wanted Rate (Cents)"
      source="not_wanted_rate_cents"
      fullWidth={quickCreate}
    />
    <NumberInput
      label="Requested Rate (Cents)"
      source="requested_rate_cents"
      fullWidth={quickCreate}
    />
    <EnumSelectInput label="Job Type" source="job_type" fullWidth={quickCreate} />
    <TextInput label="Description" source="description" fullWidth={quickCreate} />
    <SelectInput source="source" choices={SOURCE_OPTIONS} fullWidth={quickCreate} />
    <TextInput label="Zip Code" source="zip_code" fullWidth={quickCreate} />
  </>
);

const WageLogsCreate = () => (
  <Create
    resource="WageLog"
    redirect={(_resource, _id, data) => `Professional/${data.professional_id}/show/wage_logs`}
  >
    <SimpleForm>
      <WageLogsFormFields />
    </SimpleForm>
  </Create>
);
export default WageLogsCreate;
