import React from 'react';
import {
  TextField,
  Datagrid,
  Pagination,
  ReferenceManyField,
  useRecordContext,
  DeleteWithConfirmButton,
  FunctionField,
} from 'react-admin';
import IdField from 'components/IdField';
import { Box, Typography } from '@mui/material';
import { currentTimeZone } from 'utils/time';
import DateTimeField from 'components/DateTimeField';
import { RequirementForm, RequirementFormType } from 'resources/requirements/RequirementForm';

const transformPayload = (payload) => {
  // do not send position_id if specialty was selected
  if (payload.specialty_id) {
    return {
      ...payload,
      position_id: null,
    };
  }
  return payload;
};

const AdditiveRequirementsTable = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="Requirement"
      target="company_id"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography gutterBottom variant="h6">
          Requirements
        </Typography>
        <RequirementForm
          type={RequirementFormType.company}
          defaultValues={{ company_id: record.id }}
          transform={transformPayload}
        />
      </Box>
      <Datagrid bulkActionButtons={false}>
        <IdField />
        <TextField source="condition.label" />
        <DateTimeField label="Created At" source="created_at" timeZone={currentTimeZone()} />
        <TextField source="kind" />
        <TextField label="Cohort" source="cohort_eligibility" />
        <TextField label="Position" source="position.display_name" />
        <TextField label="Specialty" source="specialty.label" />
        <FunctionField render={DeleteWithConfirmButton} />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default React.memo(AdditiveRequirementsTable);
